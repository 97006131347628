var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "container", staticClass: "container" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.postContact()
          }
        }
      },
      [
        _c("div", { staticClass: "profile-area" }, [
          _c("div", {
            staticClass: "profile-img",
            style: {
              backgroundImage:
                "url(" + _vm.$lib.cdnUrl(_vm.$route.query.teamImgUrl) + ")"
            }
          }),
          _c("div", {
            staticClass: "profile-img",
            style: {
              backgroundImage:
                "url(" + _vm.$lib.imgUrl("img/logo-circle.png", true) + ")"
            }
          })
        ]),
        _vm._m(0),
        _c("p", { staticClass: "block-subtitle text-center" }, [
          _vm._v("예약 전 연락처 교환 및 전화 상담은 불가합니다.")
        ]),
        _c("div", {
          staticClass: "ui-border-line ui-h-0",
          staticStyle: { "margin-top": "35px" }
        }),
        _c(
          "div",
          {
            staticClass: "ui-input-wrap",
            on: {
              click: function(e) {
                return _vm.scrollToTextarea(e)
              }
            }
          },
          [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.formData.text,
                  expression: "formData.text"
                }
              ],
              ref: "textarea",
              attrs: { minlength: "1", required: "" },
              domProps: { value: _vm.formData.text },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.formData, "text", $event.target.value)
                }
              }
            })
          ]
        ),
        _c("footer-box", {
          attrs: {
            single: "",
            submitText: "문의하기",
            submitCb: function() {},
            submitDisabled: !_vm.validate
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "text-center",
        staticStyle: {
          padding: "2rem 0",
          "font-size": "2.6rem",
          "font-weight": "500",
          color: "#000"
        }
      },
      [
        _vm._v("무엇이든 물어보세요."),
        _c("br"),
        _vm._v("전문가가 직접 답변을 드려요.")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }