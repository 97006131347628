<template>
    <div class="container" ref="container">
        <form @submit.prevent="postContact()">
            <div class="profile-area">
                <div
                    class="profile-img"
                    :style="{ backgroundImage: `url(${$lib.cdnUrl($route.query.teamImgUrl)})` }"
                ></div>
                <div
                    class="profile-img"
                    :style="{ backgroundImage: `url(${$lib.imgUrl('img/logo-circle.png', true)})` }"
                ></div>
            </div>

            <div
                class="text-center"
                style="padding: 2rem 0; font-size: 2.6rem; font-weight: 500; color: #000;"
            >무엇이든 물어보세요.<br>전문가가 직접 답변을 드려요.</div>
            <p class="block-subtitle text-center">예약 전 연락처 교환 및 전화 상담은 불가합니다.</p>
            <div class="ui-border-line ui-h-0" style="margin-top: 35px"></div>

            <div class="ui-input-wrap" @click="e => scrollToTextarea(e)">
                <textarea ref="textarea" v-model="formData.text" minlength="1" required></textarea>
            </div>

            <footer-box single submitText="문의하기" :submitCb="() => {}" :submitDisabled="!validate"></footer-box>
        </form>
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'

export default {
    name: 'ContactToArtist',
    components: {
        PageHeader,
        FooterBox,
    },
    data() {
        return {
            formData: {
                text: '',
            },
            confirm: false,
        }
    },
    computed: {
        validate() {
            let validate = true
            for (let key in this.formData) {
                if (!this.formData[key]) {
                    validate = false
                    break
                }
            }

            return validate
        },
    },
    methods: {
        postContact() {
            const req = {
                method: 'post',
                url: `/client/offer/${this.$route.params.offerUid}/contact`,
                data: {
                    artistidx: this.$route.query.artistidx,
                    teamName: this.$route.query.teamName,
                    detail: this.formData.text,
                },
            }

            this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (this.$lib.validNgWordCount(data, this.$store) === false) {
                        return
                    }

                    if (resultData.success) {
                        this.$root.$emit('qna:reload')
                        this.$toast('문의가 전달되었습니다. 최대한 빨리 답변드릴게요!').back()
                    } else {
                        this.$alert(resultData)
                    }
                })
                .catch(log.error)
        },
        scrollToTextarea(e) {
            this.$refs.container.scrollTop = e.target.offsetTop - window.innerHeight * 0.45
            this.$nextTick().then(() => {
                this.$refs.textarea.focus()
            })
        },
    },
}
</script>

<style lang="scss" scoped>
h4 {
    margin: 0 !important;
    font-weight: 500 !important;
    font-size: 2.6rem !important;
    line-height: 6.6rem !important;
}

input,
textarea {
    padding: 1em;
    width: 100%;
    border: 0;
    font-size: 2rem;
    line-height: 1.5;
    height: 7rem;
    border: 1px solid #979797;
    border-radius: 5px;
}

textarea {
    min-height: 14rem;
}

p {
    color: #979797;
    font-size: 2rem;

    &.block-subtitle {
        margin: -1rem 0 2rem;
        font-size: 1.9rem;
    }
}

.profile-area {
    display: flex;
    justify-content: center;
    .profile-img {
        position: relative;
        z-index: 1;
        width: 7rem;
        height: 7rem;
        background-color: #eee;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        border: 2px solid #fff;
        &:last-child {
            z-index: 0;
            margin-left: -35px;
        }
    }
}
</style>
